import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getNotificationService,
  setAllNotificationsAsReadService,
  setNotificationAsReadService
} from '../services/notificationService';

const initialState = {
  notifications: []
};

export const loadNotifications = createAsyncThunk(
  'notifications/set',
  async (props, thunkAPI) => {
    const { customFetch } = props;
    const {
      rejectWithValue,
      dispatch
    } = thunkAPI;
    const data = await customFetch(getNotificationService, {});
    if (!data) return rejectWithValue();
    dispatch(notificationsSlice.actions.setNotifications(data));
});

export const markNotificationAsRead = createAsyncThunk(
  'notification/read',
  async (props, thunkAPI) => {
    const { customFetch, id } = props;
    const {
      rejectWithValue,
      dispatch
    } = thunkAPI;
    const data = await customFetch(setNotificationAsReadService, { id });
    if (!data) return rejectWithValue();
    dispatch(notificationsSlice.actions.markAsRead({ id }));
  }
);

export const markAllNotificationsAsRead = createAsyncThunk(
  'notifications/all-read',
  async (props, thunkAPI) => {
    const { customFetch, id } = props;
    const {
      rejectWithValue,
      dispatch
    } = thunkAPI;
    const data = await customFetch(setAllNotificationsAsReadService);
    if (!data) return rejectWithValue();
    dispatch(notificationsSlice.actions.markAllAsRead());
  }
);

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    markAsRead: (state, action) => {
      const { id } = action.payload;
      state.notifications = state.notifications.map(n =>
        n._id === id ? ({...n, isRead: true}) : n
      );
    },
    markAllAsRead: (state) => {
      state.notifications = state.notifications.map(n => ({...n, isRead: true}));
    }
  },
});

export default notificationsSlice.reducer;
