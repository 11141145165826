import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchQuery: "",
  doSearch: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setDoSearch: (state, action) => {
      state.doSearch = action.payload;
    }
  },
});

export const { setSearchQuery, setDoSearch } = searchSlice.actions;
export default searchSlice.reducer;
