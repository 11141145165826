import extractParams from "../utils/extractParams";
import axiosConfig from "./axiosConfig";

const fetchUsersService = async (formData = {}) => {
	const params = extractParams(formData, "id", "query");
	const { data } = await axiosConfig.get("/users", { params });
	return data;
};

const updateUserService = async (formData = {}) => {
	const params = extractParams(formData, "name", "canHavePremiumName", "password");
	const { data } = await axiosConfig.patch("/users", params);
	return data;
};

const updateDomainService =  async (formData = {}) => {
	const params = extractParams(formData, "domain");
	const { data } = await axiosConfig.patch("/users/dm", params);
	return data;
};

const updateFCMTokenService =  async (token) => {
  const { data } = await axiosConfig.post("/users/token", { token });
  return data;
};

const followService =  async ({ id }) => {
  const { data } = await axiosConfig.post("/users/follow", { id });
  return data;
};

const updateDPService = async (formData = {}) => {
	const headers = { "Content-Type": "multipart/form-data" };
	const { data } = await axiosConfig.patch("/users/dp", formData, { headers });
	return data;
};

const getSagaCountService = async (formData = {}) => {
	const headers = { "Content-Type": "multipart/form-data" };
	const params = extractParams(formData, "userId");
	const { data } = await axiosConfig.get("/users/getSagaCount", { params });
	return data;
};

export {
  fetchUsersService,
  updateUserService,
  updateDPService,
  updateDomainService,
  getSagaCountService,
  followService,
  updateFCMTokenService,
};
