import { sendIcon } from '../../assets';
import React, { useState } from 'react';

const initialForm = { reason: "" };

const Reason = ({ onClose, onSubmit, placeholder }) => {
  const [form, setForm] = useState(initialForm);

  const submitHandler = async (event) => {
    event.preventDefault();
    const { reason } = form;
    setForm(initialForm);
    onClose();
    onSubmit({reason});
  }

  return (
    <article className="createpost gradient-border reportpost">
      <form onSubmit={submitHandler}>
        <textarea
          placeholder={placeholder}
          value={form.reason}
          onChange={e => setForm({ ...form, reason: e.target.value })}
        />

        <div className="btns">
          <button type="submit" aria-label="submit">
            <img src={sendIcon} alt="send"/>
          </button>
        </div>
      </form>
    </article>
  );
}

export default Reason;
