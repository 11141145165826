import { useDispatch, useSelector } from 'react-redux';
import Notification from './Notification';
import { useEffect } from 'react';
import {
  loadNotifications,
  markAllNotificationsAsRead,
} from '../../features/notificationSlice';
import useFetch from '../../hooks/useFetch';
import Button from '../Button/Button';

const Notifications = () => {
  const { notifications } = useSelector(store => store.notification);
  const dispatch = useDispatch();
  const customFetch = useFetch();

  useEffect(() => {
    dispatch(loadNotifications({customFetch}));
  }, [dispatch]);

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsRead({customFetch}));
  }

  return (
    <>
      {notifications.length === 0 ? (
        <h3>There aren't any notifications</h3>
      ) : (
        <>
          <Button
            title="Mark all as read"
            isDisabled={notifications.every(n => n.isRead)}
            onClick={markAllAsRead}
          />

          {notifications.map(n => (
            <Notification notification={n} key={n._id} />
          ))}
        </>
      )}
    </>
  )
}

export default Notifications;
