import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import Reason from '../Reason/Reason';
import React from 'react';
import { doBan } from '../../features/adminSlice';

const Ban = ({ close }) => {
  const placeholder = "What is the reason for the ban?";
  const dispatch = useDispatch();
  const customFetch = useFetch();
  const { admin: { banType, banTypeId } } = useSelector((state) => state);


  const submitHandler = (form) => {
    const { reason } = form;
    dispatch(doBan({customFetch, reason, banType, banTypeId}));
    close();
  };

  return (
    <>
      <Reason onSubmit={submitHandler} onClose={close} placeholder={placeholder}></Reason>
    </>
  );
};

export default Ban;
