import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from "@solana/wallet-adapter-react";
import { login } from "../../features/userSlice";
import {setIsLoading, showModal} from "../../features/modalSlice";
import { loginWithPasswordService, loginWithWalletService } from "../../services/authServices";
import bs58 from 'bs58';
import solbooklogo from "../../assets/solbooklogo.png";
import Button from '../Button/Button'; // Ensure this path is correct
import './login.css';


const Login = ({ setIsRegistering, isActive }) => {
  const { connected, connecting, publicKey, signMessage, disconnect } = useWallet();
	const [isConnecting, setIsConnecting] = useState(0);
  const [loginStrategy, setLoginStrategy] = useState('wallet');
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isActive) return;
		if (connected && !connecting && isConnecting == 0) {
			disconnect();
		}
		else if (!connected && connecting && isConnecting == 0) {
			setIsConnecting(1);
		}
	}, [connected, connecting, isActive, isConnecting]);

	useEffect(() => {
		if (!isActive) return;
		(async () => {
			if (connected && isConnecting === 1 && loginStrategy === 'wallet') {
				setIsConnecting(2);
				try {
					const originalMessage = "Sign in to authenticate";
					const encodedMessage = new TextEncoder().encode(originalMessage);
					const signature = await signMessage(encodedMessage);
					const signatureBase58 = bs58.encode(new Uint8Array(signature));

					const userData = await loginWithWalletService({
						walletAddress: publicKey.toBase58(),
						signature: signatureBase58,
						message: originalMessage,
					});

					if (userData) {
						dispatch(login(userData));
					}
				} catch (error) {
					disconnect();
					if (error.response && [404, 422].includes(error.response.status)) {
						// This assumes the error response has a 'message' field with the error description
						const errorMessage = error.response.data.message || 'Are you new here? Please make an account first.';
						dispatch(showModal({ msg: errorMessage }));
					} else {
						console.error('Login with wallet failed:', error);
						// Handle other types of errors or show a generic error message
						dispatch(showModal({ msg: 'Login failed, please try again.' }));
					}
				} finally {
					dispatch(setIsLoading(false));
				}
				setIsConnecting(0);
			}
		})();
	}, [connected, isConnecting, isActive]);

  const handelStrategyClick = () => {
    switch (loginStrategy) {
      case 'wallet':
        setLoginStrategy('password');
        break;
      case 'password':
        setLoginStrategy('wallet');
        break;
      default:
        setLoginStrategy('wallet');
    }
  };

  const handleLoginButton = async () => {
    const userData = await loginWithPasswordService(username, password).catch(e => {
      dispatch(showModal(e.response.data));
    });

    if (userData) {
      dispatch(login(userData));
    }
  }

	return (
		<div className="login" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<img src={solbooklogo} alt="SolBook Logo" style={{ marginBottom: '20px', maxWidth: '200px', height: 'auto' }}/>
      <div className="animated-container">
        { loginStrategy === 'wallet' && <WalletMultiButton/> }
        { loginStrategy === 'password' && (
          <div className="using-password">
            <label htmlFor="name">Username</label>
            <input
              type="text"
              id="name"
              placeholder="username"
              required
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <label htmlFor="name">Password</label>
            <input
              type="password"
              id="password"
              placeholder="password"
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Button title="Login"
                    backgroundColor="var(--primary-500)"
                    onClick={handleLoginButton}
                    isDisabled={!username || !password}
                    isActive={true}
            ></Button>
          </div>
        )}
      </div>
      <div style={{marginTop: "1rem"}}>
        use
        <span style={{cursor: 'pointer', color: '#007AFF'}} onClick={handelStrategyClick}>
          { loginStrategy === 'wallet' && ' password' }
          { loginStrategy === 'password' && ' wallet' }
        </span>
      </div>
      <p>
        Don't have an account? <br/>
        <span onClick={() => setIsRegistering(true)} style={{cursor: 'pointer', color: '#007AFF'}}>Register</span>
      </p>
    </div>
  );
};

export default Login;
