import { Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { setEditingPost, setReportingPost } from "../features/postSlice";
import Appbar from "../components/Appbar/Appbar";
import Backdrop from "../components/Backdrop/Backdrop";
import EditPost from "../components/EditPost/EditPost";
import ProtectedRoute from "./ProtectedRoute";
import ProgressBar from "react-topbar-progress-indicator";
import ReportPost from '../components/ReportPost/ReportPost';
import Terms from "../pages/Terms/Terms";
import Privacy from "../pages/Privacy/Privacy";
import Cookies from "../pages/Cookies/Cookies";
import Ban from '../components/Ban/Ban';
import { resetBanData } from '../features/adminSlice';

const Home = lazy(() => import("../pages/Home/Home"));
const SinglePost = lazy(() => import("../pages/Singlepost/SinglePost"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const Chat = lazy(() => import("../pages/Chat/Chat"));
const MessengerPage = lazy(() => import("../pages/Messenger/Messenger"));
const NotFound = lazy(() => import("../pages/NotFound/NotFound"));

const Layout = () => {
	const {
    admin: { banTypeId, banType },
		post: { editingPost, reportingPost },
	} = useSelector(state => state);

	const dispatch = useDispatch();

	const closeEditing = () => {
		dispatch(setEditingPost({}));
	};

	const closeReporting = () => {
		dispatch(setReportingPost({}));
	}

  const closeBanModal = () => {
    dispatch(resetBanData());
  }

	return (
		<>
			<Backdrop show={!!editingPost._id} onClose={closeEditing}>
				<EditPost close={closeEditing} />
			</Backdrop>

			<Backdrop show={!!reportingPost._id} onClose={closeReporting}>
				<ReportPost close={closeReporting}></ReportPost>
			</Backdrop>

      <Backdrop show={!!banTypeId && !!banType} onClose={closeBanModal}>
        <Ban close={closeBanModal}></Ban>
      </Backdrop>

			<Appbar />
			<Suspense fallback={<ProgressBar />}>
				<Outlet />
			</Suspense>
		</>
	);
};

const Router = () => {
	const authenticate = Comp => (
		<ProtectedRoute>
			<Comp />
		</ProtectedRoute>
	);
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route path="/terms" element={<Terms />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/cookies" element={<Cookies />} />
				<Route index element={<Home />} />
				<Route path="/post/:id" element={<SinglePost />} />
				<Route path="/user/:id" element={<Profile />} />
				<Route path="/chat" element={authenticate(Chat)} />
				<Route path="/chat/messenger" element={authenticate(MessengerPage)} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

export default Router;
