import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const useUsersTags = (setValue) => {
  const [value, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [activeTagIndex, setActiveTagIndex] = useState(null);
  const inputRef = useRef();
  const suggestionListRef = useRef();
  const [position, setPosition] = useState(null);
  const { users: { users } } = useSelector((state) => state);

  const selectUser = (name) => {
    if (activeTagIndex !== null) {
      const parts = value.split('@');

      parts[activeTagIndex] = `${name}`;
      const newValue = parts.join('@') + ' ';
      setInputValue(newValue);
      setValue(newValue);
      setSuggestions([]);
      setActiveTagIndex(null);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleUsersTags = (inputValue) => {
    setInputValue(inputValue);
    if (!inputValue.length) {
      setSuggestions([]);
    }

    if (inputValue.includes('@')) {
      const parts = inputValue.split('@');
      const query = parts[parts.length - 1];
      setActiveTagIndex(parts.length - 1);

      if (query.length >= 3) {
        const filteredUsers = users.filter(user =>
          user.name.toLowerCase().includes(query.toLowerCase())
        );
        setSuggestions(filteredUsers);
      } else {
        setSuggestions([]);
      }
    }

    const position = suggestionListRef?.current?.offsetHeight;
    setPosition(position);
  }

  const getSuggestionsList = (suggestionPosition) => {
    const styles = suggestionPosition === "below" ? null : { top: `${position ? '-' + position + 'px' : 0}`};

    return (
      <div>
        {suggestions.length > 0 && (
          <div className="suggestion-list" style={styles} ref={suggestionListRef}>
            {suggestions.map((user) => (
              <div className="suggestion" key={user._id} onClick={() => selectUser(user.name)}>
                {user.name}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  return ({ handleUsersTags, inputRef, getSuggestionsList });
}

export default useUsersTags;
