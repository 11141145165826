import { months } from "../DATE";

export const getDateString = date => {
  if (!date) return "";
  let newDate = new Date(date);
  newDate = `${newDate.getDate()} ${months[newDate.getMonth()]} ${newDate.getFullYear()}`;
  return newDate;
};

export const getTimeString = (dateString) => {
  if (!dateString) return "";

  // Convert date string to a Date object
  const date = new Date(dateString);

  // Format the time based on the browser's locale
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false, // Use 24-hour format
  };

  return date.toLocaleTimeString(undefined, options);
};

export const getTimeStringWithDiff = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = now - date;
  const minutesDiff = timeDiff / (1000 * 60); // Convert milliseconds to minutes

  if (minutesDiff < 1) {
    // Less than 1 minute ago
    return "Just now";
  } else if (minutesDiff < 60) {
    // Less than 1 hour ago, calculate the time difference in minutes
    const diffMinutes = Math.floor(minutesDiff);
    return `${diffMinutes} minute${diffMinutes === 1 ? '' : 's'} ago`;
  } else if (minutesDiff < 1440) { // 1440 minutes in a day
    // Less than 24 hours ago, calculate the time difference in hours
    const diffHours = Math.floor(minutesDiff / 60);
    return `${diffHours} hour${diffHours === 1 ? '' : 's'} ago`;
  } else {
    // More than 24 hours ago, show date
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  }
};

export default getTimeString;
