import React from "react";
import "./Terms.css";

const Terms = () => {
	return (
		<div className="terms">
			<h4>Terms and Conditions of Solbook</h4>
			<p className="terms__updated">Last updated: March 28, 2024</p>

			<h5>Welcome to Solbook!</h5>
			<p>
				Solbook is a decentralized social network platform that empowers its community to create, share, and
				engage with content in a dynamic and respectful environment. By accessing or using Solbook's services,
				you agree to comply with and be bound by these Terms and Conditions (the "Terms"). Please read these
				Terms carefully, and contact us if you have any questions. If you disagree with any part of the Terms,
				you may not access the service.
			</p>

			<h5>1. Acceptance of Terms</h5>
			<p>
				By creating an account or accessing Solbook, you confirm that you have read, understood, and agreed to
				be bound by these Terms, including any future modifications. Solbook may amend the Terms at any time by
				posting a revised version on our site. By continuing to use the service after changes are made, you
				agree to be bound by the revised Terms.
			</p>

			<h5>2. Community-Guided Content Regulation</h5>
			<p>
				Solbook's community plays a crucial role in regulating the content shared on the platform. While Solbook
				provides a framework for content sharing and interaction, the specific guidelines and rules for
				acceptable content are managed collectively by the Solbook community. Members are encouraged to
				contribute positively, respect diversity, and uphold the community's standards.
			</p>

			<h5>3. Your Account</h5>
			<p>
				You are responsible for safeguarding the keys of your wallet that you use to access Solbook and for any
				activities or actions under your account. Solbook cannot and will not be liable for any loss or damage
				arising from your failure to comply with this security obligation. You must notify Solbook immediately
				upon becoming aware of any breach of security or unauthorized use of your account.
			</p>

			<h5>4. Intellectual Property Rights</h5>
			<p>
				All content posted on Solbook by users remains the intellectual property of the respective owners. By
				posting content on Solbook, you grant Solbook a non-exclusive, royalty-free license to use, modify,
				publicly perform, publicly display, reproduce, and distribute such content on and through the service.
				This license is solely for the purpose of operating, developing, providing, and improving the Solbook
				service and researching and developing new ones.
			</p>

			<h5>5. Termination</h5>
			<p>
				Solbook reserves the right to terminate or suspend access to our service immediately, without prior
				notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.
				All provisions of the Terms which by their nature should survive termination shall survive termination,
				including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
				liability.
			</p>

			<h5>6. Changes to the Service</h5>
			<p>
				Solbook reserves the right at any time and from time to time to modify or discontinue, temporarily or
				permanently, the service (or any part thereof) with or without notice. Solbook shall not be liable to
				you or to any third party for any modification, price change, suspension, or discontinuance of the
				service.
			</p>

			<h5>Contact Us</h5>
			<p>
				If you have any questions about these Terms, please contact us in our Telegram. Link on our main website.
			</p>
		</div>
	);
};

export default Terms;