import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUsersService, followService } from '../services/userServices';
import { getAllChats } from './messageSlice';
import { logout } from './userSlice';
import handleFollowers from '../utils/handleFollowers';

const initialState = {
  users: [],
  usersOnline: [],
};

export const getUsers = createAsyncThunk('users/getUsers', async (props, thunkAPI) => {
  const { customFetch } = props;
  const { fulfillWithValue, rejectWithValue, dispatch, getState } = thunkAPI;
  const data = await customFetch(fetchUsersService);
  if (!data) return rejectWithValue();
  const { users } = data;
  if (!getState().user.isGuest) dispatch(getAllChats({ customFetch, users }));
  return fulfillWithValue(users);
});

export const follow = createAsyncThunk('users/follow', async (props, thunkAPI) => {
  const { customFetch, id } = props;
  const { getState, fulfillWithValue, rejectWithValue } = thunkAPI;
  const data = await customFetch(followService, { id });
  if (!data) return rejectWithValue();
  const currentUserId = getState().user.id;
  return fulfillWithValue({ id, currentUserId });
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addOnline: (state, action) => {
      state.usersOnline = action.payload;
    },
    banUser: (state, action) => {
      state.users = state.users.map(user =>
        user._id === action.payload.id
          ? ({...user, banned: true})
          : user
      );
    },
    setVerifyForUser: (state, action) => {
      const { id } = action.payload;
      state.users = state.users.map(user => user._id === id ? ({...user, verified: true}) : user);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(follow.fulfilled, (state, action) => {
      const { id, currentUserId } = action.payload;
      state.users = state.users.map(user => {
        if (user._id === currentUserId) {
          if (!user.following) user.following = [];
            handleFollowers(user.following, id)
        }
        if (user._id === id) {
          if (!user.followers) user.followers = [];
          handleFollowers(user.followers, currentUserId);
        }
        return user;
      })
    });
    builder.addCase(logout.type, (state, action) => {
      return initialState;
    });
  },
});

export const { addOnline, banUser, setVerifyForUser } = usersSlice.actions;
export default usersSlice.reducer;
