import { dp } from '../../assets';
import React from 'react';
import './notification.css';
import { getTimeStringWithDiff } from '../../utils/getDateString';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { markNotificationAsRead } from '../../features/notificationSlice';
import useFetch from '../../hooks/useFetch';

const EMOJI = {
  like: '❤️',
  comment: '💬',
  repost: '🔄',
  follow: '👋',
  newPost: '📣',
  unfollow: '🥹'
}

const Notification = ({ notification }) => {
  const profileImage = notification.data.user.profileImage;
  const createdAt = getTimeStringWithDiff(notification.createdAt);
  const dispatch = useDispatch();
  const customFetch = useFetch();

  const getTitle = (title) => {
    const titleToArray = title.split(' ');
    const username = titleToArray.shift();
    return (
      <>
        <strong>{username}</strong> {titleToArray.join(' ')}
      </>
    );
  }

  const buildTargetLink = () => {
    switch (notification.type) {
      case 'follow':
        return `user/${notification.data.user._id}`;
      case 'unfollow':
        return `user/${notification.data.user._id}`;
      case 'newPost':
      case 'comment':
      case 'repost':
        return `post/${notification.data.post._id}`;
      default:
        return '';
    }
  }

  const setAsRead = () => {
    if (!notification.isRead) {
      dispatch(markNotificationAsRead({customFetch, id: notification._id}));
    }
  }

  return (
    <Link
      className={!notification.isRead ? 'notification active' : 'notification'}
      onClick={setAsRead}
      to={buildTargetLink()}
    >
      <div className="left-side">
        <img
          src={ profileImage || dp }
          alt="profile_image"
          className="profilecard__dp roundimage"
        />
        <div className="dp-upload notification__type">
          { EMOJI[notification.type] }
        </div>
      </div>

      <div className="right-side">
        <div className="notification__text">{ getTitle(notification.title) }</div>
        <div className="notification__date">{ createdAt }</div>
      </div>
    </Link>
  )
}

export default Notification;
