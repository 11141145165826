import React from "react";
import { dp } from "../../assets";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../features/modalSlice";
import "./online.css";
import solbooklogo from "../../assets/solbooklogo.png";
import verifiedUsers from '../../utils/verifiedUsers'; // Ensure this path is correct


const Online = () => {
	const {
		users: { usersOnline, users },
	} = useSelector(state => state);

	const dispatch = useDispatch();

	const allUsers = () => {
		return users.map(user => (
			<Link to={`/user/${user._id}`} key={user._id} onClick={() => dispatch(toggleSidebar(false))}>
				<div className="user">
					<div>
						<img
							src={user.profileImage || dp}
							loading="lazy"
							alt={`${user.name} image`}
							className="roundimage"
						/>
					</div>


					<h3>
            {user.name || "User"}{(verifiedUsers.includes(user.name) || user.verified) &&
						<span style={{display: 'inline-flex', verticalAlign: 'middle', marginLeft: '5px'}}>
              <img
				  src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Eo_circle_light-blue_checkmark.svg/2048px-Eo_circle_light-blue_checkmark.svg.png"
				  alt="Verified"
				  style={{width: '10px', paddingBottom: '2px'}}
			  />
            </span>}
					</h3>

				</div>
			</Link>
		));
	};

	const onlineUsers = () => {
		const _usersOnline = users.filter(user => usersOnline.some(u => u.id === user._id));
		return _usersOnline.map(user => (
			<Link to={`/user/${user._id}`} key={user._id} onClick={() => dispatch(toggleSidebar(false))}>
				<div className="user" title={user.name}>
					<div className="user-avatar">
						<div className="avatar-container">
              {user.name || "User"}{(verifiedUsers.includes(user.name) || user.verified) &&
								<span className="verified-badge">
                  <img
					  src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Eo_circle_light-blue_checkmark.svg/2048px-Eo_circle_light-blue_checkmark.svg.png"
					  alt="Verified"
					  style={{width: '10px'}}
				  />
                </span>
							}
							<div className="green">
								<img
									src={user.profileImage || dp}
									alt={`${user.name} image`}
									className="roundimage"
								/>
							</div>

						</div>
					</div>
				</div>
			</Link>
		));
	};

	return (
		<section className="online">
			<div className="home-icon-container">
				<Link to="/"  onClick={() => dispatch(toggleSidebar(false))}>
					<img src={solbooklogo} alt="SolBook Logo"
						 style={{marginBottom: '20px', width: '90px', height: 'auto'}}/>
				</Link>
			</div>
			{/*<h2>Users Online - {usersOnline.length}</h2>*/}
			{/*<div className="online-users">{onlineUsers()}</div>*/}
			<h2>All Users - {users.length}</h2>
			{allUsers()}
		</section>
	);
};

export default Online;
