import React from 'react';
import './button.css';

const Button = ({title, onClick, isActive, backgroundColor, isDisabled = false}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick && onClick();
  }

  const buildStyles = () => {
    const activeBtn = {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      color: 'var(--primary-400)'
    };
    const defaultBackgroundColor = 'var(--primary-400)';

    return {
      backgroundColor: backgroundColor ?? defaultBackgroundColor,
      ...(isActive && activeBtn)
    }
  }

  return (
    <button
      style={buildStyles()}
      className="app-button"
      onClick={handleClick}
      disabled={isDisabled}
    >{title}</button>
  )
};

export default Button;
