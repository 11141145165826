const handleFollowers = (userIds, id) => {
  const idx = userIds?.findIndex(userId => userId === id);
  if (idx >= 0) {
    userIds.splice(idx, 1);
  } else {
    userIds.push(id);
  }
};

export default handleFollowers;
