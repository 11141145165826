import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from "@solana/wallet-adapter-react";
import {setIsLoading, showModal} from "../../features/modalSlice";
import { login } from "../../features/userSlice";
import useFetch from "../../hooks/useFetch";
import { registerWithWallet } from "../../services/authServices";
import solbooklogo from "../../assets/solbooklogo.png"; // Ensure this path is correct

const Register = ({ setIsRegistering, isActive }) => {
    const { connected, connecting, publicKey, signMessage, disconnect } = useWallet();
	const [isConnecting, setIsConnecting] = useState(0);
	const dispatch = useDispatch();
	const [userName, setUserName] = useState("");
	const customFetch = useFetch();

	useEffect(() => {
		if (!isActive) return;
		if (connected && !connecting && isConnecting == 0) {
			disconnect();
		}
		else if (!connected && connecting && isConnecting == 0) {
			setIsConnecting(1);
		}
	}, [connected, connecting, isActive, isConnecting]);

	useEffect(() => {
		if (!isActive) return;
		(async () => {
			if (connected && isConnecting === 1) {
				setIsConnecting(2);
				try {
					const message = `To avoid digital dognappers, sign below to authenticate with CryptoCorgis.`;
					const encodedMessage = new TextEncoder().encode(message);
					const signature = await signMessage(encodedMessage);
          localStorage.removeItem('sagaNameHintWasShown');

					const payload = {
						name: userName,
						walletAddress: publicKey.toBase58(),
						signature: Array.from(signature)
					};

					const data = await customFetch(registerWithWallet, payload);
					if (data) {
						dispatch(login(data));
					}
				} catch (error) {
					console.error("Registration failed:", error);
					window.alert("Registration failed, please try again.");
				} finally {
					dispatch(setIsLoading(false));
				}
				setIsConnecting(0);
			}
		})();
	}, [connected, isConnecting, isActive]);

	return (
		<div className="register" style={{ flexDirection: 'column', alignItems: 'center' }}>
			<img src={solbooklogo} alt="SolBook Logo" style={{ marginBottom: '20px', maxWidth: '200px', height: 'auto' }}/>
			<div>
				<label htmlFor="name">Username</label>
				<input
					type="text"
					id="name"
					placeholder="johnsol"
					value={userName}
					required
					onChange={(e) => setUserName(e.target.value)}
					style={{ width: '100%', padding: '8px', boxSizing: 'border-box', marginBottom: '20px' }}
				/>
			</div>
			<WalletMultiButton />
			<p>
				Already have an account? <span onClick={() => setIsRegistering(false)} style={{ cursor: 'pointer', color: '#007AFF', textDecoration: 'underline' }}>Login</span>
			</p>
		</div>
	);
};

export default Register;
