import React, { useCallback, useMemo, useState } from "react";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  UnsafeBurnerWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from "@solana/web3.js";

const NETWORK = process.env.REACT_APP_NETWORK;
const RPC_URL = process.env.REACT_APP_RPC_URL;

console.log({NETWORK, RPC_URL});

export const SolanaWalletProvider = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = NETWORK || WalletAdapterNetwork.Mainnet;

  const endpoint = RPC_URL || useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    [network]
  );

  const config = {
    disableRetryOnRateLimit: false, 
    commitment: 'confirmed'
  };

  return (
    <ConnectionProvider endpoint={endpoint} config={config}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
