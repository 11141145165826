import axiosConfig from './axiosConfig';

const getNotificationService = async (body) => {
  const { data: { notifications } } = await axiosConfig.post("/notifications", body);
  return notifications;
};

const setNotificationAsReadService = async ({ id }) => {
  const { data } = await axiosConfig.patch(`/notifications/${id}/read`);
  return data;
};

const setAllNotificationsAsReadService = async () => {
  const { data } = await axiosConfig.patch(`/notifications/all-read`);
  return data;
};

export { getNotificationService, setNotificationAsReadService, setAllNotificationsAsReadService }
