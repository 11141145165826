import axiosConfig from "./axiosConfig";
const registerWithWallet = async (payload) => {
	try {
		const { data } = await axiosConfig.post("/auth/register", payload);
		return data;
	} catch (error) {
		console.error('Registration with wallet failed:', error);
		throw error;
	}
};

const loginWithWalletService = async ({ walletAddress, signature, message }) => {
	console.log('I have', walletAddress, signature, message);

	try {
		const response = await axiosConfig.post("/auth/login", {
			walletAddress,
			signature,
			message
		});
		return response.data;
	} catch (error) {
		console.error('Login with wallet failed:', error);
		throw error;
	}
};

const loginWithPasswordService = async (username, password) => {
  try {
    const response = await axiosConfig.post("/auth/login", {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('Login with password failed:', error);
    throw error;
  }
}

export {
  loginWithWalletService,
  registerWithWallet,
  loginWithPasswordService
};
