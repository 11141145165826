export default function trigger(state, payload) {
  const { singlePost, allPosts, userPosts } = state;
  const { id, userId, add, type } = payload;
  if (singlePost._id === id) {
    add
      ? singlePost[type].push(userId)
      : (singlePost[type] = singlePost[type].filter(ele => ele !== userId));
  }
  let post = allPosts.posts.find(post => post._id === id);
  let _post = userPosts.posts.find(post => post._id === id);

  if (add) {
    if (post) post[type].push(userId);
    if (_post) _post[type].push(userId);
  } else {
    post && (post[type] = post[type].filter(ele => ele !== userId));
    _post && (_post[type] = _post[type].filter(ele => ele !== userId));
  }
}
